import React, { useContext, useState, useEffect } from "react";
import { auth } from "../../firebase/firebase";
import { getFirestore, doc, getDoc } from "firebase/firestore"; // Import Firestore functions
import { onAuthStateChanged } from "firebase/auth";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [isEmailUser, setIsEmailUser] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, initializeUser);
    return unsubscribe;
  }, []);

  async function initializeUser(user) {
    if (user) {
      const firestore = getFirestore();
      const userRef = doc(firestore, "users", user.uid);
      const userDoc = await getDoc(userRef);

      if (userDoc.exists()) {
        const userData = userDoc.data();
        setCurrentUser({
          ...user,
          companyName: userData.companyName || "",
          companyId: userData.companyId || "",
          transactionsCollection: userData.transactionsCollection || "",
          samsaraOrganizationId: userData.samsaraOrganizationId,
          cardsCollection: userData.cardsCollection, 
        });
      } else {
        setCurrentUser({ ...user, companyName: "", companyId: "" });
      }

      // Check if provider is email and password login
      const isEmail = user.providerData.some(
        (provider) => provider.providerId === "password"
      );
      setIsEmailUser(isEmail);


      setUserLoggedIn(true);
    } else {
      setCurrentUser(null);
      setUserLoggedIn(false);
    }

    setLoading(false);
  }

    // Function to get ID Token
    async function getIdToken() {
      if (auth.currentUser) {
        return await auth.currentUser.getIdToken();
      }
      throw new Error("No user is authenticated");
    }

  const value = {
    userLoggedIn,
    isEmailUser,
    currentUser,
    setCurrentUser,
    getIdToken,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}